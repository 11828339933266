<template>
  <v-app-bar fixed elevate-on-scroll v-bind:class="headerClass">
    <v-container>
      <v-row no-gutters>
        <v-toolbar-title>
          <router-link class="logo-font" :to="{ name: 'home' }">
            <span class="sr-only">Aria la qualità dell'aria in Piemonte</span>
            <div class="brand"></div>
            <div class="brand-mobile"><span class="sr-only">Menu</span></div>
          </router-link>
        </v-toolbar-title>
        <v-col class="menu d-none d-md-block">
          <!--p class="logo-dx">
            <img
              alt="Piemonte"
              src="../../assets/stylesheets/im/logo-piemonte-color.png"
            />
          </p-->
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn text v-bind="attrs" v-on="on"> Qualità dell'aria </v-btn>
            </template>
            <v-list class="co2">
              <v-list-item>
                <router-link :to="{ name: 'inquinanti' }">
                  Inquinanti
                </router-link>
              </v-list-item>
              <v-list-item>
                <router-link :to="{ name: 'salute' }">
                  Effetti sulla salute e sull'ambiente
                </router-link>
              </v-list-item>
              <v-list-item>
                <router-link :to="{ name: 'fonti' }">
                  Fonti emissive
                </router-link>
              </v-list-item>
              <v-list-item>
                <router-link :to="{ name: 'dati-qualita-aria' }">
                  Accesso ai dati misurati
                </router-link>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn text v-bind="attrs" v-on="on"> Valutare l'aria </v-btn>
            </template>
            <v-list class="pva">
              <v-list-item>
                <router-link :to="{ name: 'sistema' }">
                  Sistema regionale
                </router-link>
              </v-list-item>
              <v-list-item>
                <router-link :to="{ name: 'emissioni' }">
                  Inventario emissioni
                </router-link>
              </v-list-item>
              <v-list-item>
                <router-link :to="{ name: 'modelli' }">
                  Modelli di qualità dell'aria
                </router-link>
              </v-list-item>
              <v-list-item>
                <router-link :to="{ name: 'monitoraggio' }">
                  Rete di monitoraggio
                </router-link>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn text v-bind="attrs" v-on="on">
                Politiche per l'aria
              </v-btn>
            </template>
            <v-list class="aci">
              <v-list-item>
                <router-link :to="{ name: 'piano' }">
                  Piano Regionale di Qualità dell'Aria
                </router-link>
              </v-list-item>
              <v-list-item>
                <router-link :to="{ name: 'misure' }">
                  Misure di piano
                </router-link>
              </v-list-item>
              <v-list-item>
                <router-link :to="{ name: 'progetti' }"> Progetti </router-link>
              </v-list-item>
              <v-list-item>
                <router-link :to="{ name: 'reti' }">
                  Reti di collaborazione
                </router-link>
              </v-list-item>
              <v-list-item>
                <router-link :to="{ name: 'stili' }">
                  Inquinamento e stili di vita
                </router-link>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pt-lg-0">
          <h1>
            {{ title }}
          </h1>
        </v-col>
      </v-row>
    </v-container>

    <v-app-bar-nav-icon class="d-md-none">
      <span class="sr-only">Menu</span>
      <v-icon v-if="!drawer" @click="drawer = true" aria-label="Apri menu"
        >mdi-menu</v-icon
      >
      <v-icon v-if="drawer" @click="drawer = false" aria-label="Chiudi menu"
        >mdi-close</v-icon
      >
    </v-app-bar-nav-icon>

    <v-navigation-drawer v-model="drawer" absolute temporary class="d-lg-none">
      <v-expansion-panels accordion>
        <v-toolbar-title>
          <router-link class="logo-font" :to="{ name: 'home' }">
            <div class="brand-mobile">
              <span class="sr-only">Aria la qualità dell'aria in Piemonte</span>
            </div>
          </router-link>
        </v-toolbar-title>
        <v-expansion-panel>
          <v-expansion-panel-header>Qualità dell'aria</v-expansion-panel-header>
          <v-expansion-panel-content class="co2">
            <v-list>
              <v-list-item>
                <router-link :to="{ name: 'inquinanti' }">
                  Inquinanti
                </router-link>
              </v-list-item>
              <v-list-item>
                <router-link :to="{ name: 'salute' }">
                  Effetti sulla salute e sull'ambiente
                </router-link>
              </v-list-item>
              <v-list-item>
                <router-link :to="{ name: 'fonti' }">
                  Fonti emissive
                </router-link>
              </v-list-item>
              <v-list-item>
                <router-link :to="{ name: 'dati-qualita-aria' }">
                  Accesso ai dati misurati
                </router-link>
              </v-list-item>
            </v-list>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>Valutare l'aria</v-expansion-panel-header>
          <v-expansion-panel-content class="pva">
            <v-list>
              <v-list-item>
                <router-link :to="{ name: 'sistema' }">
                  Sistema regionale
                </router-link>
              </v-list-item>
              <v-list-item>
                <router-link :to="{ name: 'emissioni' }">
                  Inventario emissioni
                </router-link>
              </v-list-item>
              <v-list-item>
                <router-link :to="{ name: 'modelli' }">
                  Modelli di qualità dell'aria
                </router-link>
              </v-list-item>
              <v-list-item>
                <router-link :to="{ name: 'monitoraggio' }">
                  Rete di monitoraggio
                </router-link>
              </v-list-item>
            </v-list>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header
            >Politiche per l'aria</v-expansion-panel-header
          >
          <v-expansion-panel-content class="aci">
            <v-list>
              <v-list-item>
                <router-link :to="{ name: 'piano' }">
                  Piano Regionale di Qualità dell'Aria
                </router-link>
              </v-list-item>
              <v-list-item>
                <router-link :to="{ name: 'misure' }">
                  Misure di piano
                </router-link>
              </v-list-item>
              <v-list-item>
                <router-link :to="{ name: 'progetti' }"> Progetti </router-link>
              </v-list-item>
              <v-list-item>
                <router-link :to="{ name: 'reti' }">
                  Reti di collaborazione
                </router-link>
              </v-list-item>
              <v-list-item>
                <router-link :to="{ name: 'stili' }">
                  Inquinamento e stili di vita
                </router-link>
              </v-list-item>
            </v-list>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <div class="footer">
        <a href="#">
          <p class="small">Questo servizio è parte di</p>
          <img
            alt="Piemonte"
            src="../../assets/stylesheets/im/logo-piemonte-b.png"
          />
          <span class="scopri">SCOPRI</span>
        </a>
      </div>
    </v-navigation-drawer>
  </v-app-bar>
</template>

<script>
export default {
  name: "Header",
  props: ["headerClass", "title"],
  data() {
    return {
      drawer: false
    };
  }
};
</script>
